/*

  display success & error messages

  # usage

  import toast from "~/utils/toast";

  toast.error.now();
  toast.error.afterRedirect();
  toast.success.now();
  toast.success.afterRedirect();

*/

import { Notyf } from 'notyf';
const notyf = new Notyf({
  duration: 7000,
  ripple: false,
  dismissible: true,
  position: {
    x: 'center',
    y: 'bottom',
  }
});


/*

  DISPLAY ON PAGE LOAD

*/

const storedErrorMessage = localStorage.getItem("augusta_stored_error_message");
if (storedErrorMessage) {
  displayErrorNow(storedErrorMessage);
  localStorage.removeItem("augusta_stored_error_message");
}

const storedSuccessMessage = localStorage.getItem("augusta_stored_success_message");
if (storedSuccessMessage) {
  displaySuccessNow(storedSuccessMessage);
  localStorage.removeItem("augusta_stored_success_message");
}

const flashMessageElems = document.querySelectorAll(".js\\:flash-message");
flashMessageElems.forEach(flashMessageElem => {
  if (flashMessageElem.classList.contains("alert")) {
    displayErrorNow(flashMessageElem.innerText);
  } else {
    displaySuccessNow(flashMessageElem.innerText);
  }
});


/* 

  DISPLAY FUNCTIONS

*/

function displayErrorNow (errorMessage) {
  notyf.error(errorMessage);
}

function displayErrorAfterRedirect (errorMessage) {
  localStorage.setItem("augusta_stored_error_message", errorMessage)
}

function displaySuccessNow (successMessage) {
  notyf.success(successMessage);
}

function displaySuccessAfterRedirect (successMessage) {
  localStorage.setItem("augusta_stored_success_message", successMessage)
}

export default {
  error: {
    now: displayErrorNow,
    afterRedirect: displayErrorAfterRedirect
  },
  success: {
    now: displaySuccessNow,
    afterRedirect: displaySuccessAfterRedirect
  }
};



